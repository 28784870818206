.contact{
    font-family: fontGT;
    font-size: 36px;
}

.modal-box{
    position: relative;
    width: 400px;
    min-height: 400px;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    font-family: fontGTLight;
}

.email-input{
    /*border: none;*/
    /*border-bottom: solid 1px;*/
    width: 100%;
}
.email-input:focus{
    /*outline: none;*/
}

.textArea{
    width: 100%;
    height: 130px;
}

.send-btn{
    margin-top: 30px;
    align-self: end;
    border: solid 1px;
    width: fit-content;
    padding: 2px 15px;
    border-radius: 20px;
    cursor: pointer;
    user-select: none;
    font-family: fontGTLight;
}

.title{
    font-size: 12px;
    font-family: fontGTLight;
    padding: 10px 0px;
}

.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
}


@media (max-width: 991px) {
    .contact{
        font-size: 20px;
    }

    .modal-box{
        width: 90%;
    }
}
