.icon-box{
    transition: all 100ms linear;
    width:45px;
    height:45px;
    position:relative;
}

.hover-box{
    width:45px;
    height:45px;
    position:relative;
}

.hover-box:hover .icon-box{
    transition: all 100ms linear;
    width:34px;
    height:34px;
    margin-top: 5px;
    margin-left: 5px;
    position:relative;
}



.hover-box:hover .imgOpacity{
    opacity: 0.5;
    transition: all 100ms linear;
}