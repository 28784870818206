.icon-width{
    width: 20vw;
}

.icon-box-l{
    transition: all 5s linear;
    width:40vw;
    height:42vw;
    position:relative;
    /*margin-top: 50px;*/
}

.hover-box-l{
    width:40vw;
    height:42vw;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hover-box-l:hover .icon-box-l{
    transition: all 5s linear;
    width:35vw;
    height:38vw;
    /*margin-top: 50px;*/
    /*margin-left: 50px;*/
    /*margin-bottom: 50px;*/
    position:relative;
}


@media (max-width: 991px) {
    .icon-width{
        width: 25vw;
    }

    .icon-box-l{
        transition: all 5s linear;
        width:50vw;
        height:52vw;
        position:relative;
        /*margin-top: 50px;*/
    }

    .hover-box-l{
        width:50vw;
        height:50vw;
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hover-box-l:hover .icon-box-l{
        transition: all 5s linear;
        width:43vw;
        height:48vw;
        /*margin-top: 50px;*/
        /*margin-left: 50px;*/
        /*margin-bottom: 50px;*/
        position:relative;
    }

}


/*.hover-box:hover .imgOpacity{*/
/*    opacity: 0.5;*/
/*    transition: all 100ms linear;*/
/*}*/