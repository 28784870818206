div.footer-center {
  text-align: left;
}

div > a,
#footer {
  color: #ffffff;
  font-size: 12px;
  padding: 50px 0;
}

#footer > div {
  padding: 2px 0px;
}

.bottom-bar {
  margin-top: 30px;
  width: 100%;
  /*margin-left: 10%;*/
  padding-top: 1rem;
  background-color: #042735;
  /*color: rgba(255, 255, 255, 0.65);*/
  padding-bottom: 16px;
}

.ant-layout-footer{
  padding: 0 !important;
}

@media screen and (max-width: 992px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  div.footer-center {
    text-align: center;
  }
}

.footer-wrap{
  margin-bottom: 5rem;
}

.footer-content{
    color: #fff;
    font: 500 48px/62px fontGT;
    text-align: left;
}

.footer-tail{
  font-family: fontGTLight;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  font-size: 12px;
  color: #9b9b9b;
}

@media (max-width: 991px) {
  .footer-content {
    max-width: 100%;
    font-size: 24px;
    line-height: 32px;
    margin-left: 5%;
  }

  .footer-tail{
    align-items:flex-start;
    margin-left: 5%;
  }
}


