.header{
	/*background: #FFFFFF;*/
}

.footer {
	background: #042735;
	color: #778393;
	text-align: center;
	padding-top: 40px;
     /*margin-bottom: 0px;*/
}


table, td, th {
	border: 1px solid #d8d8d8;
	height: 36px;
}

table {
	border-collapse: collapse;
	width: 100%;
}

th {
	height: 36px;
	background-color: #eaf9ff;
	text-align: center;
	line-height: 36px;
	font-weight: bolder;
	/*height: 70px;*/
}

@font-face {
	font-family: "fontStyle";  /* 可以随意命名 */
	src: url("./statics/font/ysbth.TTF") format("truetype");  /* 指定字体文件的路径和格式 */
}

@font-face {
	font-family: "numberStyle";  /* 可以随意命名 */
	src: url("./statics/font/BebasNeue-Regular.ttf") format("truetype");  /* 指定字体文件的路径和格式 */
}

.tail-title{
	font-size: 16px;
}

.solution-detail{
	line-height: 34px;
	font-size: 14px;
}

.product-detail{
	font-size: 14px;
	line-height: 34px;
	font-weight: bolder;
}

.concat-detail{
	font-size: 14px;
	line-height: 34px;
}

@font-face {
	font-family: 'fontGTLight';
	src: url('./statics/font/plusrise/GT-Walsheim-Pro-Light.ttf') format('truetype');
}