.logo {
    width: 120px;
    height: 80px;
    display: flex;
    align-items: center;
    float: left;
    margin-left:5%;
    position: absolute;
}

img {
    vertical-align: middle;
    margin-top: 0px;
}
.header-title{
    margin-left:12px;
    font-weight:bolder;
    font-size:24px;
    color: white;
    width: 200px;
}


.ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    height: auto;
    line-height: 45px;
    padding-left: 0rem;
    /*padding-right: 10rem;*/
    background-color: #052C38;
}

@media (max-width: 991px) {
    .ant-layout-header {
        padding: 0;
    }

}


.floatRight {
    float: right;
}

.btn{
    font-family: fontGTLight;
    /*font-weight: 200;*/
}

.underline{
    position: relative;
    font-family: fontGT;
}


/*菜单选中后的下划线*/
.underline::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
    left: 0;
    bottom: 20px;

}
/*.btn {*/
/*	float: right;*/
/*	*/
/*}*/