@font-face {
    font-family: 'fontGTLightObl';
    src: url('../../statics/font/plusrise/GT-Walsheim-Pro-Light-Oblique.ttf') format('truetype');
}

@font-face {
    font-family: 'fontGTLight';
    src: url('../../statics/font/plusrise/GT-Walsheim-Pro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'fontGTObl';
    src: url('../../statics/font/plusrise/GT-Walsheim-Pro-Medium-Oblique.ttf') format('truetype');
}

@font-face {
    font-family: 'fontGT';
    src: url('../../statics/font/plusrise/GT-Walsheim-Pro-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'fontGTBold';
    src: url('../../statics/font/plusrise/GT-Walsheim-Pro-Bold.ttf') format('truetype');
}

.font-position{
    position: absolute;
    bottom: 10%;
    left:5%
}

.background-banner {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #052C38;
}

.img {
    aspect-ratio: 5.26;
    object-fit: auto;
    object-position: center;
    width: 100%;
    margin-top: 49px;
}

.green-font {
    color: #a9d914;
    font: 20px/150% fontGTLight, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.impact {
    color: #fff;
    margin-top: 22px;
    font: 28px/115% fontGTLight, sans-serif;
}



.background-banner2 {
    width: 100%;
    min-height: 40vh;
    padding: 80px 0;
    height: auto;
    position: relative;
    background-color: #fff;
    font-family: fontGTLight;
    /*display: flex;*/
}

.banner2-title {
    /*width: 100%;*/
    background-color: #ffffff;
    align-self: start;
    padding-top: 36px;
    padding-left: 5%;
    /*padding-left: 18px;*/
    padding-bottom: 0px;
    font: 72px fontGT;
}

#contact{
    font-size: 48px;
    padding-top: 16px;
}



.large-icon {
    position: absolute;
    left: 50%;
    top: 10%;
}


.box1 {
    font-family: fontGT,
    sans-serif;
    align-self: start;
    margin:0 0 0 18px;
    font-size: 32px;
    margin-left: 5%;
}



.box2{
    font-family: fontGT,
    sans-serif;
    align-self: end;
    margin: 12px 175px 0 0;
    font-size: 32px;
}

.shanghai{
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    font-size: 24px;
    color: #000;
    width: fit-content;
    margin-right: 5%;
}

.shanghai-2 {
    font-family: fontGT,
    sans-serif;
    font-weight: 500;
    line-height: 150%;
}

.shanghai-3{
    font-family:fontGTLight,
    sans-serif;
    font-weight: 300;
    /*line-height: 36px;*/
    margin-top: 20px;
    /*width: 20vw;*/
}


.munich {
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    font-size: 24px;
    color: #000;
    width: 30%;
}

.munich-2 {
    font-family: fontGT,
    sans-serif;
    font-weight: 500;
    line-height: 150%;
}

.munich-3 {
    font-family:fontGTLight,
    sans-serif;
    font-weight: 300;
    /*line-height: 36px;*/
    margin-top: 20px;
    /*width: 20vw;*/
}


.plus-rise{
    float: right;
    marginRight: '10vw';
    font-size: 24px;
    margin-right: 5%;
}

.flex-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


@media (max-width: 991px) {

    .background-banner2 {
        padding: 40px 0;
    }
    .large-icon {
        top:18%;
        left: 40%;
    }
    .background-banner {
        height: 68vh;
    }
    .flex-box{
        flex-direction: column;
        align-items: flex-end;
    }
    .plus-rise{
        float: right;
        margin-right: 5%;
        /*float: left;*/
        margin-left: 5%;
        font-size: 16px;
        /*marginRight: '10vw';*/
    }
    .banner2-title {
        width: 60%;
    }

    .img {
        max-width: 90%;
        margin-top: 40px;
    }

    .green-font {
        max-width: 100%;
    }

    .impact {
        max-width: 100%;
    }

    .banner2-title {
        font-size:3rem;
    }

    .box1 {
        max-width: 100%;
        margin-top: 0px;
        line-height: 35px;
    }

    .box2 {
        margin-right: 10px;
        font-size: 24px;
    }

    .munich {
        /*float: right;   */
        margin-right: 5%;
        margin-left: 5%;
        width: 60%;
        /*width: fit-content;*/
        margin-top: 40px;
        font-size: 16px;
    }

    .munich-2 {
        max-width: 100%;
    }

    .munich-3 {
        max-width: 100%;
        margin-top: 20px;
        /*width: 60vw;*/
    }

    .shanghai {
        margin-left: 5%;
        width: 60%;
        /*width: fit-content;*/
        margin-top: 40px;
        /*align-self: flex-start;*/
        font-size: 16px;
    }

    .shanghai-2 {
        max-width: 100%;
    }

    .shanghai-3 {
        max-width: 100%;
        margin-top: 20px;
        /*width: 60vw;*/
    }

}



