html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
	color: black !important;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.banner-button{
	color: white;
	width: 210px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 20px;
	background-color: #0e7bff;
	border: solid 1px #0e7bff;
	cursor: pointer;
	height: 64px;
	padding: 20px 43px 18px 40px;
	border-radius: 36px;
}

.banner-button:hover{
	background-color: #2f8dfd;
}

.banner-button-noColor{
	color: white;
	width: 210px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 20px;
	border-radius: 32px;
	border: solid 1px white;
	cursor: pointer;
}

.banner-button-white{
	color: #0e7bff;
	width: 210px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 20px;
	border-radius: 32px;
	background-color: white;
	border: solid 1px white;
	cursor: pointer;
}

.white-button{
	color: #20aee5;
	width: 168px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 20px;
	border-radius: 27px;
	background-color: white;
	cursor: pointer;
}

.logo {
    width: 120px;
    height: 80px;
    display: flex;
    align-items: center;
    float: left;
    margin-left:5%;
    position: absolute;
}

img {
    vertical-align: middle;
    margin-top: 0px;
}
.header-title{
    margin-left:12px;
    font-weight:bolder;
    font-size:24px;
    color: white;
    width: 200px;
}


.ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    height: auto;
    line-height: 45px;
    padding-left: 0rem;
    /*padding-right: 10rem;*/
    background-color: #052C38;
}

@media (max-width: 991px) {
    .ant-layout-header {
        padding: 0;
    }

}


.floatRight {
    float: right;
}

.btn{
    font-family: fontGTLight;
    /*font-weight: 200;*/
}

.underline{
    position: relative;
    font-family: fontGT;
}


/*菜单选中后的下划线*/
.underline::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
    left: 0;
    bottom: 20px;

}
/*.btn {*/
/*	float: right;*/
/*	*/
/*}*/
.icon-box{
    -webkit-transition: all 100ms linear;
    transition: all 100ms linear;
    width:45px;
    height:45px;
    position:relative;
}

.hover-box{
    width:45px;
    height:45px;
    position:relative;
}

.hover-box:hover .icon-box{
    -webkit-transition: all 100ms linear;
    transition: all 100ms linear;
    width:34px;
    height:34px;
    margin-top: 5px;
    margin-left: 5px;
    position:relative;
}



.hover-box:hover .imgOpacity{
    opacity: 0.5;
    -webkit-transition: all 100ms linear;
    transition: all 100ms linear;
}
div.footer-center {
  text-align: left;
}

div > a,
#footer {
  color: #ffffff;
  font-size: 12px;
  padding: 50px 0;
}

#footer > div {
  padding: 2px 0px;
}

.bottom-bar {
  margin-top: 30px;
  width: 100%;
  /*margin-left: 10%;*/
  padding-top: 1rem;
  background-color: #042735;
  /*color: rgba(255, 255, 255, 0.65);*/
  padding-bottom: 16px;
}

.ant-layout-footer{
  padding: 0 !important;
}

@media screen and (max-width: 992px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  div.footer-center {
    text-align: center;
  }
}

.footer-wrap{
  margin-bottom: 5rem;
}

.footer-content{
    color: #fff;
    font: 500 48px/62px fontGT;
    text-align: left;
}

.footer-tail{
  font-family: fontGTLight;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  font-size: 12px;
  color: #9b9b9b;
}

@media (max-width: 991px) {
  .footer-content {
    max-width: 100%;
    font-size: 24px;
    line-height: 32px;
    margin-left: 5%;
  }

  .footer-tail{
    align-items:flex-start;
    margin-left: 5%;
  }
}



@font-face {
    font-family: 'fontGTLightObl';
    src: url(/static/media/GT-Walsheim-Pro-Light-Oblique.b6ec0702.ttf) format('truetype');
}

@font-face {
    font-family: 'fontGTLight';
    src: url(/static/media/GT-Walsheim-Pro-Light.0c6888d7.ttf) format('truetype');
}

@font-face {
    font-family: 'fontGTObl';
    src: url(/static/media/GT-Walsheim-Pro-Medium-Oblique.6feefadb.ttf) format('truetype');
}

@font-face {
    font-family: 'fontGT';
    src: url(/static/media/GT-Walsheim-Pro-Medium.373ccb83.ttf) format('truetype');
}

@font-face {
    font-family: 'fontGTBold';
    src: url(/static/media/GT-Walsheim-Pro-Bold.d16fab6a.ttf) format('truetype');
}

.font-position{
    position: absolute;
    bottom: 10%;
    left:5%
}

.background-banner {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #052C38;
}

.img {
    aspect-ratio: 5.26;
    object-fit: auto;
    object-position: center;
    width: 100%;
    margin-top: 49px;
}

.green-font {
    color: #a9d914;
    font: 20px/150% fontGTLight, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.impact {
    color: #fff;
    margin-top: 22px;
    font: 28px/115% fontGTLight, sans-serif;
}



.background-banner2 {
    width: 100%;
    min-height: 40vh;
    padding: 80px 0;
    height: auto;
    position: relative;
    background-color: #fff;
    font-family: fontGTLight;
    /*display: flex;*/
}

.banner2-title {
    /*width: 100%;*/
    background-color: #ffffff;
    align-self: start;
    padding-top: 36px;
    padding-left: 5%;
    /*padding-left: 18px;*/
    padding-bottom: 0px;
    font: 72px fontGT;
}

#contact{
    font-size: 48px;
    padding-top: 16px;
}



.large-icon {
    position: absolute;
    left: 50%;
    top: 10%;
}


.box1 {
    font-family: fontGT,
    sans-serif;
    align-self: start;
    margin:0 0 0 18px;
    font-size: 32px;
    margin-left: 5%;
}



.box2{
    font-family: fontGT,
    sans-serif;
    align-self: end;
    margin: 12px 175px 0 0;
    font-size: 32px;
}

.shanghai{
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    font-size: 24px;
    color: #000;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 5%;
}

.shanghai-2 {
    font-family: fontGT,
    sans-serif;
    font-weight: 500;
    line-height: 150%;
}

.shanghai-3{
    font-family:fontGTLight,
    sans-serif;
    font-weight: 300;
    /*line-height: 36px;*/
    margin-top: 20px;
    /*width: 20vw;*/
}


.munich {
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    font-size: 24px;
    color: #000;
    width: 30%;
}

.munich-2 {
    font-family: fontGT,
    sans-serif;
    font-weight: 500;
    line-height: 150%;
}

.munich-3 {
    font-family:fontGTLight,
    sans-serif;
    font-weight: 300;
    /*line-height: 36px;*/
    margin-top: 20px;
    /*width: 20vw;*/
}


.plus-rise{
    float: right;
    marginRight: '10vw';
    font-size: 24px;
    margin-right: 5%;
}

.flex-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


@media (max-width: 991px) {

    .background-banner2 {
        padding: 40px 0;
    }
    .large-icon {
        top:18%;
        left: 40%;
    }
    .background-banner {
        height: 68vh;
    }
    .flex-box{
        flex-direction: column;
        align-items: flex-end;
    }
    .plus-rise{
        float: right;
        margin-right: 5%;
        /*float: left;*/
        margin-left: 5%;
        font-size: 16px;
        /*marginRight: '10vw';*/
    }
    .banner2-title {
        width: 60%;
    }

    .img {
        max-width: 90%;
        margin-top: 40px;
    }

    .green-font {
        max-width: 100%;
    }

    .impact {
        max-width: 100%;
    }

    .banner2-title {
        font-size:3rem;
    }

    .box1 {
        max-width: 100%;
        margin-top: 0px;
        line-height: 35px;
    }

    .box2 {
        margin-right: 10px;
        font-size: 24px;
    }

    .munich {
        /*float: right;   */
        margin-right: 5%;
        margin-left: 5%;
        width: 60%;
        /*width: fit-content;*/
        margin-top: 40px;
        font-size: 16px;
    }

    .munich-2 {
        max-width: 100%;
    }

    .munich-3 {
        max-width: 100%;
        margin-top: 20px;
        /*width: 60vw;*/
    }

    .shanghai {
        margin-left: 5%;
        width: 60%;
        /*width: fit-content;*/
        margin-top: 40px;
        /*align-self: flex-start;*/
        font-size: 16px;
    }

    .shanghai-2 {
        max-width: 100%;
    }

    .shanghai-3 {
        max-width: 100%;
        margin-top: 20px;
        /*width: 60vw;*/
    }

}




.icon-width{
    width: 20vw;
}

.icon-box-l{
    -webkit-transition: all 5s linear;
    transition: all 5s linear;
    width:40vw;
    height:42vw;
    position:relative;
    /*margin-top: 50px;*/
}

.hover-box-l{
    width:40vw;
    height:42vw;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hover-box-l:hover .icon-box-l{
    -webkit-transition: all 5s linear;
    transition: all 5s linear;
    width:35vw;
    height:38vw;
    /*margin-top: 50px;*/
    /*margin-left: 50px;*/
    /*margin-bottom: 50px;*/
    position:relative;
}


@media (max-width: 991px) {
    .icon-width{
        width: 25vw;
    }

    .icon-box-l{
        -webkit-transition: all 5s linear;
        transition: all 5s linear;
        width:50vw;
        height:52vw;
        position:relative;
        /*margin-top: 50px;*/
    }

    .hover-box-l{
        width:50vw;
        height:50vw;
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hover-box-l:hover .icon-box-l{
        -webkit-transition: all 5s linear;
        transition: all 5s linear;
        width:43vw;
        height:48vw;
        /*margin-top: 50px;*/
        /*margin-left: 50px;*/
        /*margin-bottom: 50px;*/
        position:relative;
    }

}


/*.hover-box:hover .imgOpacity{*/
/*    opacity: 0.5;*/
/*    transition: all 100ms linear;*/
/*}*/
.header{
	/*background: #FFFFFF;*/
}

.footer {
	background: #042735;
	color: #778393;
	text-align: center;
	padding-top: 40px;
     /*margin-bottom: 0px;*/
}


table, td, th {
	border: 1px solid #d8d8d8;
	height: 36px;
}

table {
	border-collapse: collapse;
	width: 100%;
}

th {
	height: 36px;
	background-color: #eaf9ff;
	text-align: center;
	line-height: 36px;
	font-weight: bolder;
	/*height: 70px;*/
}

@font-face {
	font-family: "fontStyle";  /* 可以随意命名 */
	src: url(/static/media/ysbth.1726685c.TTF) format("truetype");  /* 指定字体文件的路径和格式 */
}

@font-face {
	font-family: "numberStyle";  /* 可以随意命名 */
	src: url(/static/media/BebasNeue-Regular.b2b29306.ttf) format("truetype");  /* 指定字体文件的路径和格式 */
}

.tail-title{
	font-size: 16px;
}

.solution-detail{
	line-height: 34px;
	font-size: 14px;
}

.product-detail{
	font-size: 14px;
	line-height: 34px;
	font-weight: bolder;
}

.concat-detail{
	font-size: 14px;
	line-height: 34px;
}

@font-face {
	font-family: 'fontGTLight';
	src: url(/static/media/GT-Walsheim-Pro-Light.0c6888d7.ttf) format('truetype');
}
/*==============================调整导航栏menu组件样式====================================*/

:root{
    /*--themeColor:#20aee5*/
    --themeColor:#0e7bff;
    --fontSize:16px;
}



    /*修改menu导航栏的下拉组件样式*/
.ant-menu-submenu-popup{
    background: rgba(0,0,0,0.5);
}

/*设置菜单背景色*/
.ant-menu {
    background-color: rgba(0, 0, 0, 0.01) !important;
    color: white !important;
}

/*设置菜单文字颜色*/
.ant-menu-horizontal > .ant-menu-item > a {
    color: white;
}

/*菜单之间的间距*/
@media (max-width: 991px) {
    .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu:not(:last-child) {
        margin-right: 2rem !important;
    }
}


.ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #1890ff;
}

/*去掉导航栏顶部的border-buttom*/
.ant-menu-horizontal{
    border-bottom:none;
}


.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    /*background-color: rgba(255,255,255,0.5);*/
}

.ant-menu-item > a{
    color: white;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: rgba(255, 255, 255, 0.2);
}

/*导航栏选中后字体颜色*/
/*#20aee5*/
.ant-menu-horizontal > .ant-menu-item-selected > a{
    color: #0e7bff;
    color: var(--themeColor);
    /*选中后，文字底部下划线*/
    box-shadow: 0 -2px 0 0 #0e7bff inset;
    box-shadow: 0 -2px 0 0 var(--themeColor) inset;
    font-size: 16px;
    font-size: var(--fontSize)
}
/*鼠标移到导航栏选上时字体颜色*/
.ant-menu-horizontal > .ant-menu-item > a:hover{
    font-size: 16px;
    font-size: var(--fontSize);
    color: #0e7bff;
    color: var(--themeColor)
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
    color: #0e7bff;
    color: var(--themeColor)
}

/*选中后下拉菜单的字体颜色*/
.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #fff !important;
    font-weight: bolder;
    /*box-shadow: 0 -2px 0 0 #ffffff inset;*/
    position: relative;
}



.ant-menu-submenu-selected{
    /*中后下拉菜单的下划线*/
    box-shadow: 0 -2px 0 0 #0e7bff inset;
    box-shadow: 0 -2px 0 0 var(--themeColor) inset;
}

.ant-menu-item > a:hover{
    color: #0e7bff;
    color: var(--themeColor)
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
    /*border-bottom-color: var(--themeColor);*/
    /*border-bottom: solid 2px;*/
    border: none;
    /*box-shadow: 0 -2px 0 0 var(--themeColor) inset;*/
}


/*设置menu选中后下划线的宽度*/
.ant-menu-item, .ant-menu-submenu-title {
    font-size: 16px;
    font-size: var(--fontSize);
    padding: 0px;
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu:not(:last-child){
    border: none;
    margin-right: 3rem;
}


/*调整导航栏高度*/
/*.ant-layout-header{*/
/*    padding-top: 0.5rem;*/
/*    padding-bottom: 0.5rem;*/
/*}*/

.ant-layout{
    background-color: #ffffff;
}
/*===================调整Button组件样式==========================*/
.ant-btn-primary{
    background-color:#0e7bff;
    background-color:var(--themeColor);
    border-color: #0e7bff;
    border-color: var(--themeColor);
}
.ant-btn > i, .ant-btn > span{
    font-weight: bolder;
}


/*===================调整输入框之间的间距==========================*/
.ant-form-item{
    margin-bottom: 18px;
}

/*=====================调整头像的样式============================*/

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    min-width: auto;
    margin-right: auto;
    font-size: inherit;
}

/*=============调整走马灯背景===============*/
.ant-carousel .slick-slide{
    background-color: transparent;
}


/*走马灯按钮颜色*/
.ant-carousel .slick-dots li button{
    background-color: gray;
}

.ant-carousel .slick-dots li.slick-active button{
    background-color:#0e7bff;
    background-color:var(--themeColor);
    width: 16px;
}

/*调整走马灯底部按钮位置*/
.ant-carousel .slick-dots-bottom {
    bottom: 130px;
}

/*======================tab组件样式====================================*/
.ant-tabs-nav .ant-tabs-tab-active {
    color: #0e7bff;
    color: var(--themeColor);
    font-weight: 600;
}


/*居中输管理TMS解决方案上方的几个tab*/
.ant-tabs-nav-scroll{
    display: flex;
    justify-content: center;
}

.ant-tabs-nav{
    font-weight: 400;
    font-size: 24px;
}

.ant-tabs-ink-bar{
    background-color: #0e7bff;
    background-color: var(--themeColor);
}



/*======================设置select组件高度========================*/
.ant-select-selection--single{
    height: 2.5rem;

}

.ant-select-selection--single .ant-select-selection__rendered{
    line-height: 2.2rem;
}

/*=================去掉input输入框等组件label后面的：=======================*/
.ant-form-item-label > label::after{
    content: '';
}
.contact{
    font-family: fontGT;
    font-size: 36px;
}

.modal-box{
    position: relative;
    width: 400px;
    min-height: 400px;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    font-family: fontGTLight;
}

.email-input{
    /*border: none;*/
    /*border-bottom: solid 1px;*/
    width: 100%;
}
.email-input:focus{
    /*outline: none;*/
}

.textArea{
    width: 100%;
    height: 130px;
}

.send-btn{
    margin-top: 30px;
    align-self: end;
    border: solid 1px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 15px;
    border-radius: 20px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-family: fontGTLight;
}

.title{
    font-size: 12px;
    font-family: fontGTLight;
    padding: 10px 0px;
}

.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


@media (max-width: 991px) {
    .contact{
        font-size: 20px;
    }

    .modal-box{
        width: 90%;
    }
}

